import { MAIN_COLOR } from '../../utils/theme';

export interface ShlinkLogoProps {
  color?: string;
  className?: string;
}

export const ShlinkLogo = ({ color = MAIN_COLOR, className }: ShlinkLogoProps) => (
  // <svg className={className} viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
  //   <g fill={color}>
  //   </g>
  // </svg>
  <div className={className}>
    <img src="/logo.svg" alt={color} />
  </div>
);
